import React, { useState } from "react";
import { CustomLink, CustomExternalLink, Modal } from "@common";
import {
  StyledAppBar,
  DesktopMenuContainer,
  DesktopMenuItem,
  MobileMenuContainer,
  MobileMenu,
  CTAButton,
  Logo,
  MobileMenuItem,
} from "./Navbar.styles";
import content from "@app/content/content-en.js";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@assets/icons/MenuIcon.svg";
import Container from "@mui/material/Container";
import SignUpPopUp from "./components/SignUpPopUp/SignUpPopUp";

/*
MUI COMPONENT USED: 
https://mui.com/material-ui/react-app-bar/#app-bar-with-responsive-menu

- have converted all sx props to styled component styling
- have removed the settings part [visible after click on profile image]

 */

const navbarContent = content.navbar;
const pages = navbarContent.navbarLinks.links;

function ResponsiveAppBar() {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [signupModalIsOpen, setSignupModalIsOpen] = useState(false);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <StyledAppBar position="static">
      <Container maxWidth="false">
        <Toolbar disableGutters>
          <MobileMenuContainer>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <img
                src={MenuIcon}
                alt=""
                style={{
                  width: "1.24675rem",
                  height: "1.03719rem",
                }}
              />
            </IconButton>
            <MobileMenu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
            >
              {pages
                .filter((page) => page !== "aboutUs")
                .map((page) => (
                  <MobileMenuItem key={page} onClick={handleCloseNavMenu}>
                    <CustomLink
                      to={navbarContent.navbarLinks[page].url}
                      activeClassName="active"
                    >
                      {page}
                    </CustomLink>
                  </MobileMenuItem>
                ))}
              <MobileMenuItem>
                <CustomExternalLink
                  href={process.env.GATSBY_WEBAPP_BASE_URL}
                  target="_blank"
                >
                  {navbarContent.navbarButtons.login.text}
                </CustomExternalLink>
              </MobileMenuItem>
            </MobileMenu>
          </MobileMenuContainer>

          <CustomLink to="/">
            <Logo src={navbarContent.logo.src} alt="" />
          </CustomLink>

          {/* TODO: aboutUs page is commented */}
          <DesktopMenuContainer>
            {pages
              .filter((page) => page !== "aboutUs")
              .map((page) => (
                <DesktopMenuItem key={page} onClick={handleCloseNavMenu}>
                  <CustomLink
                    to={navbarContent.navbarLinks[page].url}
                    activeClassName="active"
                  >
                    {navbarContent.navbarLinks[page].text}
                  </CustomLink>
                </DesktopMenuItem>
              ))}
            <DesktopMenuItem>
              <CustomExternalLink
                href={process.env.GATSBY_WEBAPP_BASE_URL}
                target="_blank"
              >
                {navbarContent.navbarButtons.login.text}
              </CustomExternalLink>
            </DesktopMenuItem>
          </DesktopMenuContainer>

          <CTAButton
            onClick={() => {
              setSignupModalIsOpen(true);
            }}
          >
            {navbarContent.navbarButtons.getStarted.text}
          </CTAButton>
        </Toolbar>
      </Container>

      <Modal isOpen={signupModalIsOpen} setIsOpen={setSignupModalIsOpen}>
        <SignUpPopUp setIsOpen={setSignupModalIsOpen} />
      </Modal>
    </StyledAppBar>
  );
}
export default ResponsiveAppBar;
