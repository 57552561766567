/**
 * This file contains the application's colors.
 *
 * Define color here instead of duplicating them throughout the components.
 * That allows to change them more easily later on.
 */

export const BLACK = "#1D2327";
export const WHITE = "#FFF";
export const PINK = "#FF7BA9";
export const GREY = "#3a3e42";
export const WHITE_ALPHA13 = "rgba(255, 255, 255, 0.13)";
export const WHITE_ALPHA77 = "rgba(255, 255, 255, 0.77)";
export const ANTIQUE_WHITE = "#fcedda";
export const WAX_FLOWER_WHITE = "#f8c49c";
export const BATTLESHIP_GREY = "#818181";
export const DARK = "#212529";
export const JUNGLE_GREEN_DARK = "#1d2327";
export const MEDIUM_GREEN = "#28a745";
export const JASPER_RED = "#dc3545";
export const AZURE_BLUE = "#1890ff";
export const BOULDER_GREY = "#777777";
export const LIGHT_PINK = "#ed77a2";
export const DARK_PINK = "#ec6798";
export const TRANSPARENT = "rgba(0, 0, 0, 0)";
export const CLOUDY_GREY = "#6a6a6a";
export const SILVER_CHALICE = "#ababab";
export const VAMPIRE_GREY = "#535352";
export const CLOUD = "#c9c5b8";
export const SATIN_LINEN = "#e8e4dA";
export const WARM_GREY = "#959082";
export const RIVER_BED = "#434B50";
export const HAZEL = "#977721";
export const TUMERIC = "#dab745";
export const YELLOWY_BROWN = "#b38c08";
export const BROWN_BEAR = "#7B5531";
export const CLAY_BROWN = "#B47640";
export const MILK_CHOCOLATE = "#7B4F27";
export const RED_ERROR = "#FF9494";
export const BORDER_WHITE = "#EEE";
export const POP_UP_BLUR_DESKTOP = "rgba(64, 64, 64, 0.65)";
export const POP_UP_BLUR_MOBILE = "rgba(64, 64, 64, 0.12);";
export const ORANGE = "#FF7A00";
export const OUTERSPACE = "#292F32";
export const OCEAN_BLUE = '#023E9D';
export const SKY_BLUE = '#68BBE5';
export const B_BLUE = '#1575C1';
