import styled from "styled-components";
import { CustomExternalLink } from "@common";
import { RxCross1 } from "react-icons/rx";

export const PopUpContainer = styled.div`
  width: 19.87194rem;
  height: 28.125rem;
  background-color: white;
  color: black;
  border-radius: 0.63rem;
  padding: 1rem;
  ${({ theme }) => theme.breakpoints.up("sm")} {
    width: 40.9375rem;
    height: 49.91956rem;
    padding: 2.44rem 3.06rem;
  }
`;
export const PopUpHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const LogoImg = styled.img`
  width: 5.8125rem;
  height: 1.86rem;
  margin-bottom: 2rem;
  ${({ theme }) => theme.breakpoints.up("sm")} {
    width: 12.5rem;
    height: 4rem;
    margin-bottom: 3.81rem;
  }
`;
export const CloseIcon = styled(RxCross1)`
  color: ${(props) => props.theme.palette.text.secondary};
  cursor: pointer;
  font-size: 1.5rem;
  ${({ theme }) => theme.breakpoints.up("sm")} {
    display: none;
  }
`;
export const PopUpTitle = styled.div`
  font-size: 1.125rem;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.00625rem;
  font-family: "SFProDisplayBold";
  text-align: center;
  ${({ theme }) => theme.breakpoints.up("sm")} {
    font-size: 1.875rem;
    letter-spacing: 0.00625rem;
    margin-bottom: 0.25rem;
  }
`;
export const PopUpSubtitle = styled.div`
  font-size: 0.8125rem;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.00625rem;
  text-align: center;
  color: ${(props) => props.theme.palette.text.muted};
  ${({ theme }) => theme.breakpoints.up("sm")} {
    font-size: 1.5rem;
    font-family: "SFProDisplayMedium";
  }
`;
export const InstallIconsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.81rem;
  flex-direction: column;
  ${({ theme }) => theme.breakpoints.up("sm")} {
    gap: 3.41rem;
    flex-direction: row;
    justify-content: center;
  }
`;
export const InstallIcons = styled.img`
  width: 8.60163rem;
  height: 2.58063rem;
  ${({ theme }) => theme.breakpoints.up("sm")} {
    width: 12.48831rem;
    height: 4.12475rem;
  }
`;
export const StyledCustomExternalLink = styled(CustomExternalLink)`
  width: fit-content;
`;
export const QRCodeImage = styled.img`
  display: block;
  margin: auto;
  margin-top: 1.37rem;
  margin-bottom: 1rem;
  width: 7.91213rem;
  height: 7.91213rem;
  ${({ theme }) => theme.breakpoints.up("sm")} {
    width: 17.44631rem;
    height: 18.83256rem;
  }
`;
