import React from "react";
import { useSiteMetadata } from "./hooks/use-site-metadata";

/* refer to https://www.gatsbyjs.com/docs/how-to/adding-common-features/adding-seo-component/#seo-component for more info on adding seo meta tags */

export const SEO = ({ title, description, pathname, children }) => {
  const {
    title: defaultTitle,
    description: defaultDescription,
    keywords,
    siteUrl,
    previewUrl1200x1200,
    previewUrl1200x628,
  } = useSiteMetadata();

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    keywords,
    url: `${siteUrl}${pathname || ``}`,
    image1200x1200: `${previewUrl1200x1200}`,
    image1200x628: `${previewUrl1200x628}`,
  };

  return (
    <>
      {/* <!-- Primary Meta Tags --> */}
      <title>{seo.title}</title>
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, maximum-scale=1"
      />
      <meta name="title" content={seo.title} />
      <meta name="description" content={seo.description} />
      <meta name="keywords" content={seo.keywords} />

      {/* <!-- Open Graph / Facebook --> */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content={seo.url} />
      <meta property="og:title" content={seo.title} />
      <meta property="og:description" content={seo.description} />
      <meta property="og:image" content={seo.image1200x1200} />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="1200" />
      <meta property="og:image" content={seo.image1200x628} />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="628" />

      {/* <!-- Twitter --> */}
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content={seo.url} />
      <meta property="twitter:title" content={seo.title} />
      <meta property="twitter:description" content={seo.description} />
      <meta property="twitter:image" content={seo.image1200x1200} />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="1200" />
      <meta property="twitter:image" content={seo.image1200x628} />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="628" />

      {children}
    </>
  );
};
