import { InputAdornment, MenuItem } from "@mui/material";
import { FaAngleDown } from "react-icons/fa6";
import styled from "styled-components";

export const Adornment = styled(InputAdornment)`
  font-family: "SFProDisplay";
  & .MuiTypography-root {
    font-family: "SFProDisplay";
    color: ${(props) => props.theme.palette.text.primary};
  }
`;
export const DropdownIcon = styled(FaAngleDown)`
  color: ${(props) => props.theme.palette.text.secondary};
`;
export const DropdownItem = styled(MenuItem)`
  background-color: inherit;
  text-overflow: ellipsis;
  &:hover {
    background-color: ${(props) => props.theme.palette.text.secondary};
  }
  &.Mui-selected {
    background-color: inherit;
  }
  &.Mui-selected:hover {
    background-color: ${(props) => props.theme.palette.text.secondary};
  }
`;
