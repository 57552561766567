import styled from "styled-components";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { PrimaryButton } from "../common/components/PrimaryButton.styles";

export const StyledAppBar = styled(AppBar)`
  background-color: ${({ theme }) => theme.palette.background.default};
  height: 8.32813rem;
  .MuiContainer-root {
    padding: 0 2.94rem 0 7.87rem;
  }
  ${({ theme }) => theme.breakpoints.down("sm")} {
    position: absolute;
    z-index: 1;
    background-color: transparent;
    box-shadow: none;
    margin: 0;
    padding: 0;
    height: fit-content;
    .MuiContainer-root {
      padding: 0 1rem;
    }
    .MuiToolbar-root {
      gap: 1rem;
    }
    .MuiButtonBase-root {
      padding: 0;
    }
  }
`;
export const DesktopMenuContainer = styled(Box)`
  flex-grow: 1;
  justify-content: flex-end;
  align-items: center;
  gap: 5.19rem;
  margin-right: 4rem;
  display: none;
  ${({ theme }) => theme.breakpoints.up("sm")} {
    display: flex;
  }
`;
export const DesktopMenuItem = styled(MenuItem)`
  color: white;
  display: block;
  text-transform: uppercase;
  padding: 0;
  font-family: "SFProDisplay";
  font-size: 1.25rem;
  font-style: normal;
  line-height: 1.25rem;
  &:last-child {
    font-family: "SFProDisplayBold";
  }
`;
export const MobileMenuContainer = styled(Box)`
  justify-content: flex-start;
  display: flex;
  ${({ theme }) => theme.breakpoints.up("sm")} {
    display: none;
  }
`;
export const MobileMenu = styled(Menu)`
  display: block;
  .MuiMenu-paper {
    padding: 0.76rem 1.01rem;
    margin-top: 0.2rem;
    border-radius: 0.5rem;
    .MuiList-root {
      padding: 0%;
    }
  }
  ${({ theme }) => theme.breakpoints.up("sm")} {
    display: none;
  }
`;
export const MobileMenuItem = styled(MenuItem)`
  color: white;
  display: block;
  text-transform: uppercase;
  text-align: center;
  font-family: "SFProDisplay";
  font-size: 0.75rem;
  min-height: 0;
  padding: 0.54rem auto;
  &:not(:last-child) {
    border-bottom: 1px solid ${(props) => props.theme.palette.text.muted};
  }
`;
export const Logo = styled.img`
  width: 4.5625rem;
  ${({ theme }) => theme.breakpoints.up("sm")} {
    width: 13rem;
    height: 4.5rem;
    margin: 1.91rem 0;
  }
`;
export const CTAButton = styled(PrimaryButton)`
  font-family: "SFProDisplayBold";
  margin-left: auto;
  height: 2.5rem;
  &.MuiButtonBase-root {
    width: 10.75rem;
    padding: 0.875rem 0.625rem;
    min-width: 0;
  }
  ${({ theme }) => theme.breakpoints.down("sm")} {
    font-family: "SFProDisplaySemiBold";
    height: 1.125rem;
    font-size: 0.75rem;
    &.MuiButtonBase-root {
      width: 6.2rem;
      min-width: 0;
      padding: 0.875rem 0.2rem;
    }
  }
`;
