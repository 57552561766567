import React, { useEffect, useState } from "react";
import Navbar from "@modules/Navbar/index";
import { ThemeProvider } from "styled-components";
import { createTheme } from "@mui/material/styles";
import { themeColors } from "@app/themes/index";
import { StyledLayout, GlobalContainer } from "./Layout.styles.js";
import Footer from "@modules/Footer/Footer.jsx";

const onResize = () => {
  const root = window.document.documentElement;
  const screenWidth = window.innerWidth;
  const screenHeight = window.innerHeight;
  root.style.setProperty("--screen-w", screenWidth);
  root.style.setProperty("--screen-h", screenHeight);
};

function Layout({ children }) {
  const [mode, setMode] = useState("dark");

  const styledComponentTheme = themeColors[mode];
  const theme = createTheme({
    palette: {
      primary: styledComponentTheme.primary,
      secondary: styledComponentTheme.secondary,
      text: styledComponentTheme.text,
      background: styledComponentTheme.background,
    },
    colors: { ...styledComponentTheme.colors },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536,
        mobile: 0,
        tablet: 640,
        laptop: 1024,
        desktop: 1200,
      },
    },
  });

  useEffect(() => {
    onResize();
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);
  return (
    <ThemeProvider theme={theme}>
      <StyledLayout>
        <GlobalContainer>
          <Navbar />
          <main>{children}</main>
          <Footer />
        </GlobalContainer>
      </StyledLayout>
    </ThemeProvider>
  );
}

export default Layout;
