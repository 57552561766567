import React from "react";
// import {
//   faFacebook,
//   faYoutube,
//   faInstagram,
// } from "@fortawesome/free-brands-svg-icons";
import {
  // FooterContainer,
  // FooterTitle,
  // GetAppContainer,
  // GetAppContainerTitle,
  // InstallButtons,
  LegalDocumentLink,
  LegalDocuments,
  // SiteLink,
  // SiteLinks,
  // SocialMediaLinks,
  StyledFooter,
} from "./Footer.styles";
// import { ParseText } from "@common";
// import SocialMediaLink from "./components/SocialMediaLink/SocialMediaLink";
// import InstallButton from "./components/InstallButton/InstallButton";
// import playStoreIcon from "@app/assets/icons/google-play.png";
// import appStoreIcon from "@app/assets/icons/app-store.png";
import content from "@app/content/content-en.js";

const footerContent = content.footer;

function Footer() {
  return (
    <StyledFooter>
      {/* <FooterContainer>
        <SiteLinks>
          {footerContent.siteLinks.map((link, index) => (
            <SiteLink to={link.url} key={index}>
              {link.text}
            </SiteLink>
          ))}
        </SiteLinks>
        <FooterTitle>
          <ParseText text={footerContent.title} />
        </FooterTitle>
        <SocialMediaLinks>
          <SocialMediaLink icon={faFacebook} />
          <SocialMediaLink icon={faYoutube} />
          <SocialMediaLink icon={faInstagram} />
        </SocialMediaLinks>
        <GetAppContainer>
          <GetAppContainerTitle>
            {footerContent.getAppContainer.title}
          </GetAppContainerTitle>
          <InstallButtons>
            <InstallButton icon={playStoreIcon} text={"Play Store"} />
            <InstallButton icon={appStoreIcon} text={"App Store"} />
          </InstallButtons>
        </GetAppContainer>
      </FooterContainer> */}
      <LegalDocuments>
        {[
          {
            text: "Teacher's Documentation",
            url: "/docs/TeacherDoc.pdf",
          },
          {
            text: "Terms of Service",
            url: "/docs/T&C.pdf",
          },
          {
            text: "Privacy Policy",
            url: `/privacypolicy`,
          },
          { text: "Disclaimer", url: "/docs/TatvaDisclaimer.pdf" },
        ].map((link, index) => (
          <LegalDocumentLink href={link.url} key={index} target="_blank">
            {link.text}
          </LegalDocumentLink>
        ))}
      </LegalDocuments>
    </StyledFooter>
  );
}

export default Footer;
