import styled from "styled-components";

export const StyledLayout = styled.div`
  width: 100vw;
  min-height: 100vh;
  background-color: ${(props) => props.theme.colors.outerSpace};
`;

export const GlobalContainer = styled.div`
  background-color: ${(props) => props.theme.colors.background};
  min-height: 100vh;
  @media screen and (min-width: 768px) {
    display: flex;
    flex-direction: column;
    // multiplied by 1px to get correct unit
    width: calc(var(--multiplier) * var(--base-w) * 1px);
    min-height: 100vh;
    margin: auto;
  }
`;
