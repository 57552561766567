import React from "react";
import DefaultLayout from "./Layout";

const Layout = ({ children, pageContext }) => {
  // console.log("pageContext:", pageContext.layout);
  if (pageContext.layout === "withoutNav") {
    return <>{children}</>;
  }
  return <DefaultLayout>{children}</DefaultLayout>;
};

export default Layout;
