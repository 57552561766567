import React, { useState } from "react";
import { FormControl } from "@mui/material";
import { Input } from "@common";
import { Adornment, DropdownItem } from "./DropDownInput.style";

/*
MUI COMPONENT USED: 
https://mui.com/material-ui/react-text-field/#select

 */

export function DropdownInput({
  adornment,
  options,
  changeSelectedValue = () => {},
  error,
  helperText,
  ...rest
  //includes "className" and "secondary" prop
}) {
  const [selectedValue, setSelectedValue] = useState(options[0]?.value);
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    changeSelectedValue(event.target.value);
  };
  return (
    <FormControl variant="filled" {...rest}>
      <Input
        labelId="demo-simple-select-filled-label"
        id="demo-simple-select-filled"
        value={selectedValue}
        onChange={handleChange}
        error={error}
        helperText={helperText}
        select
        InputProps={{
          startAdornment: adornment ? (
            <Adornment position="start">{adornment}</Adornment>
          ) : (
            ""
          ),
        }}
        {...rest}
      >
        {options.map((item) => (
          <DropdownItem key={item.value} value={item.value}>
            {item.text}
          </DropdownItem>
        ))}
      </Input>
    </FormControl>
  );
}
