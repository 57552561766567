import { TextField } from "@mui/material";
import styled from "styled-components";

// PROPS:
// all MUI TextField Props
// secondary: boolean
// width: <valid_width_values>
export const Input = styled(TextField)`
  min-width: 20.8125rem;
  width: 100%;
  .MuiSvgIcon-root {
    color: ${(props) => props.theme.palette.text.secondary};
  }
  .MuiInputBase-root {
    border-radius: 0.44rem;
    background-color: ${(props) => props.secondary && props.theme.colors.grey};
  }
  .MuiFormLabel-root {
    font-family: "SFProDisplay";
    color: ${(props) => props.theme.palette.text.primary};
  }
  .MuiFormLabel-root.Mui-focused {
    color: ${(props) => props.theme.palette.text.secondary};
  }
`;
