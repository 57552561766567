import React from "react";
import Markdown from "marked-react";
import { StyledMarkdown } from "./ParseText.styles";

export function ParseText({ text = "" }) {
  return (
    <StyledMarkdown>
      <Markdown>{text}</Markdown>
    </StyledMarkdown>
  );
}
