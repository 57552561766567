import React, { useState, useEffect } from "react";
import Modal from "@mui/material/Modal";
import { ModalContainer } from "./MUIModal.styles";

export function MUIModal({ isOpen, setIsOpen, onClose, className, children }) {
  const [open, setOpen] = useState(isOpen);
  const handleClose = () => {
    if (onClose) {
      onClose();
    }
    setIsOpen(false);
  };

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModalContainer className={className}>{children}</ModalContainer>
      </Modal>
    </div>
  );
}
