import * as colors from "./colors";

const commonThemeColors = {
  black: colors.JUNGLE_GREEN_DARK,
  white: colors.WHITE,
  grey: colors.GREY,
  dark: colors.DARK,
  darkGrey: colors.RIVER_BED,
  transparent13: colors.WHITE_ALPHA13,
  whiteAlpha77: colors.WHITE_ALPHA77,
  error: colors.RED_ERROR,
  //TODO: remove textMuted from here and its dependencies as added at text.muted
  textMuted: colors.BATTLESHIP_GREY,
  highlight: colors.DARK_PINK,
  borderWhite: colors.BORDER_WHITE,
  popUpBlurDesktop: colors.POP_UP_BLUR_DESKTOP,
  popUpBlurMobile: colors.POP_UP_BLUR_MOBILE,
  orange: colors.ORANGE,
  outerSpace: colors.OUTERSPACE,
  blueCardBackground: `linear-gradient(204deg, ${colors.OCEAN_BLUE} -0.76%, ${colors.SKY_BLUE} 44.59%, ${colors.B_BLUE} 141%)`,
  bronzeCardBackground: `linear-gradient(204deg, ${colors.BROWN_BEAR} -0.76%, ${colors.CLAY_BROWN} 44.59%, ${colors.MILK_CHOCOLATE} 141%)`,
  silverCardBackground: `linear-gradient(203.94deg, ${colors.CLOUDY_GREY} -0.76%, ${colors.SILVER_CHALICE} 44.59%, ${colors.VAMPIRE_GREY} 141%)`,
  platinumCardBackground: `linear-gradient(203.94deg, ${colors.CLOUD} -0.76%, ${colors.SATIN_LINEN} 44.59%, ${colors.WARM_GREY} 141%)`,
  platinumCardText: colors.RIVER_BED,
  goldCardBackground: `linear-gradient(203.94deg, ${colors.HAZEL} -0.76%, ${colors.TUMERIC} 44.59%, ${colors.YELLOWY_BROWN} 141%);`,
};

const themeColors = {
  light: {
    ...commonThemeColors,
    //define all the rest for light theme before using
  },
  dark: {
    colors: { ...commonThemeColors },
    background: {
      default: colors.JUNGLE_GREEN_DARK,
      paper: colors.JUNGLE_GREEN_DARK,
    },
    text: {
      primary: colors.WHITE,
      muted: colors.BATTLESHIP_GREY,
      secondary: colors.PINK,
    },
    primary: {
      light: colors.LIGHT_PINK,
      main: colors.PINK,
      dark: colors.DARK_PINK,
      contrastText: colors.WHITE,
    },
    secondary: {
      light: "#4d5053",
      main: "#212529",
      dark: "#17191c",
      contrastText: colors.WHITE,
    },
  },
};

export { themeColors };
