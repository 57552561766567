import React from "react";
import {
  CloseIcon,
  InstallIcons,
  InstallIconsContainer,
  LogoImg,
  PopUpContainer,
  PopUpHeader,
  PopUpSubtitle,
  PopUpTitle,
  QRCodeImage,
  StyledCustomExternalLink,
} from "./SignUpPopUp.styles";
import goolgePlayIcon from "@assets/icons/google-play-large.svg";
import appStoreIcon from "@assets/icons/app-store-large.svg";

export function SignUpPopUp({ setIsOpen }) {
  return (
    <PopUpContainer>
      <PopUpHeader>
        <LogoImg src="/logo-pink.svg" />
        <CloseIcon onClick={() => setIsOpen(false)} />
      </PopUpHeader>
      <PopUpTitle>Download our Tatva app</PopUpTitle>
      <PopUpSubtitle>Scan the QR code to download the Tatva App </PopUpSubtitle>
      <QRCodeImage src={process.env.GATSBY_WEBAPP_QR_URL} />
      <InstallIconsContainer>
        <StyledCustomExternalLink
          href="https://play.google.com/store/apps/details?id=app.tatva"
          target="_blank"
        >
          <InstallIcons src={goolgePlayIcon} />
        </StyledCustomExternalLink>
        <StyledCustomExternalLink
          href="https://apps.apple.com/in/app/tatva/id1611509432"
          target="_blank"
        >
          <InstallIcons src={appStoreIcon} />
        </StyledCustomExternalLink>
      </InstallIconsContainer>
    </PopUpContainer>
  );
}

export default SignUpPopUp;
