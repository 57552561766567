import styled from "styled-components";
import { Link } from "gatsby";

export const CustomLink = styled(Link)`
  cursor: pointer;
  text-decoration: none;
  color: ${(props) => props.theme.palette.text.primary};
  &.active {
    color: ${(props) => props.theme.palette.text.secondary};
    font-family: "SFProDisplayBold";
  }
  &:hover {
    color: ${(props) =>
      props["no-highlight"] ? "inherit" : props.theme.colors.highlight};
  }
`;

export const CustomExternalLink = styled.a`
  cursor: pointer;
  text-decoration: none;
  color: ${(props) => props.theme.palette.text.primary};
  &:hover {
    color: ${(props) =>
      props["no-highlight"] ? "inherit" : props.theme.colors.highlight};
  }
`;
