import React from "react";
import { Autoplay, EffectCoverflow, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/scrollbar";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";

export function Carousal({
  items = [],
  loop = false,
  autoplayDuration,
  slidesPerView = "auto",
  coverFlowEffect,
  spaceBetween = 0,
  pagination,
  // = {
  //   rotate: 0,
  //   stretch: 0,
  //   depth: 200,
  //   modifier: 1,
  //   slideShadows: true,
  // },
  onSwiperCB = (swiper) => {
    // console.log(swiper);
  },
  onSlideChangeCB = () => {},
  className,
}) {
  let slides = items;
  if (loop) {
    if (items.length === slidesPerView) {
      slides = slides.concat(slides);
    }
  }
  return (
    <Swiper
      className={className}
      slidesPerView={slidesPerView}
      loop={loop}
      centeredSlides={true}
      autoplay={
        autoplayDuration
          ? {
              delay: autoplayDuration,
              disableOnInteraction: false,
            }
          : false
      }
      pagination={pagination ? true : false}
      spaceBetween={spaceBetween}
      onSwiper={(swiper) => onSwiperCB(swiper)}
      onSlideChange={() => {
        onSlideChangeCB();
      }}
      effect={coverFlowEffect ? "coverflow" : ""}
      coverflowEffect={coverFlowEffect}
      modules={[Autoplay, EffectCoverflow, Pagination]}
    >
      {slides.map((item, index) => (
        <SwiperSlide key={index}>{item}</SwiperSlide>
      ))}
    </Swiper>
  );
}
