import { graphql, useStaticQuery } from "gatsby";

export const useSiteMetadata = () => {
  //image and siteUrl removed from line11
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          keywords
          siteUrl
          previewUrl1200x1200
          previewUrl1200x628
        }
      }
    }
  `);

  return data.site.siteMetadata;
};
