import styled from "styled-components";
import { CustomLink, CustomExternalLink } from "../common";

export const StyledFooter = styled.div`
  color: ${(props) => props.theme.palette.text.primary};
`;
export const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.88rem;
  padding: 1.31rem 0;
  ${({ theme }) => theme.breakpoints.up("sm")} {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto auto;
  }
`;
export const SiteLinks = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 0 1.25rem;
  gap: 1.12rem;
  column-gap: 7.31rem;
  font-size: 0.75rem;
  height: fit-content;
  ${({ theme }) => theme.breakpoints.up("sm")} {
    grid-column: 2/3;
    grid-row: 1/3;
    font-size: 1.125rem;
  }
`;
export const SiteLink = styled(CustomLink)`
  width: fit-content;
`;
export const FooterTitle = styled.div`
  font-family: "SFProDisplayBold";
  font-size: 1rem;
  font-style: normal;
  line-height: normal;
  text-align: center;
  width: 20rem;
  margin: auto;
  ${({ theme }) => theme.breakpoints.up("sm")} {
    grid-column: 1/2;
    font-family: "SFProDisplayBold";
    font-size: 1.5rem;
    font-style: normal;
    width: 24rem;
    text-align: left;
    margin-left: 7.88rem;
  }
`;
export const SocialMediaLinks = styled.div`
  display: flex;
  gap: 1.56rem;
  justify-content: center;
  ${({ theme }) => theme.breakpoints.up("sm")} {
    grid-column: 1/2;
    grid-row: 2/3;
    justify-content: start;
    margin-left: 7.88rem;
  }
`;
export const GetAppContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.8rem;
  ${({ theme }) => theme.breakpoints.up("sm")} {
    grid-column: 3/4;
    grid-row: 1/3;
    justify-content: start;
  }
`;
export const GetAppContainerTitle = styled.div`
  font-family: "SFProDisplaySemiBold";
  font-size: 1.125rem;
  font-style: normal;
  line-height: normal;
`;
export const InstallButtons = styled.div`
  display: flex;
  gap: 4.19rem;
  ${({ theme }) => theme.breakpoints.up("sm")} {
    flex-direction: column;
    gap: 0.75rem;
  }
`;

export const LegalDocuments = styled.div`
  //TODO: add support for poppins
  display: flex;
  gap: 2rem;
  justify-content: space-between;
  padding: 0.81rem 0.5rem;
  background-color: ${(props) => props.theme.colors.highlight};
  color: ${(props) => props.theme.colors.white};
  ${({ theme }) => theme.breakpoints.up("sm")} {
    width: 100%;
    justify-content: end;
    padding: 1.5rem 5rem;
    font-size: 1rem;
  }
`;
export const LegalDocumentLink = styled(CustomExternalLink)`
  &:hover {
    color: ${(props) => props.theme.colors.white};
  }
`;
