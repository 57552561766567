import styled from "styled-components";
import Button from "@mui/material/Button";

export const PrimaryButton = styled(Button)`
  height: 2.5rem;
  padding: 0.875rem 1rem;
  margin: 0.2rem;
  border: none;
  border-radius: 3.75rem;
  background: ${(props) => props.theme.palette.primary.light};
  color: ${(props) => props.theme.palette.primary.contrastText};
  text-transform: uppercase;
  font-family: "SFProDisplayMedium";
  font-size: 1.125rem;
  min-width: 10.0625rem;
  &:hover {
    background: ${(props) => props.theme.palette.primary.main};
  }
  ${({ theme }) => theme.breakpoints.up("sm")} {
    font-family: "SFProDisplayBold";
    height: 3.75rem;
    min-width: 14.3125rem;
  }
`;
